import React from "react";
import Layout from '../components/layout';
import * as textCss from './modules/text.module.scss';
import {graphql, useStaticQuery} from "gatsby";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";

const CollectionPage = () => {
    const datas = useStaticQuery(graphql`
        query {
          allContentfulLaCollection {
            edges {
              node {
                titre
                sousTitre
                contenuDeLaPage {
                  raw
                }
                imageMiseEnAvant {
                  file {url}
                  title
                }
              }
            }
          }
        }`
    );

    const data = datas.allContentfulLaCollection.edges[0].node;
    const content = JSON.parse(data.contenuDeLaPage.raw);

    return (
        <Layout>
            <div className={textCss.container}>
                <h1>{data.titre}</h1>
                <h2>{data.sousTitre}</h2>
                {data.imageMiseEnAvant.file.url && <img className={textCss.img} src={data.imageMiseEnAvant.file.url} alt={data.imageMiseEnAvant.title}/>}
                <div>{documentToReactComponents(content)}</div>
            </div>
        </Layout>
    )
}

export default CollectionPage;
